import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import Header from './Header';

import { AuthContext } from '../App';
import GuestSignIn from '../views/GuestSignIn';

const HeaderPage = ({ content, noLoginRequired }) => {
  const [scrollContainerNode, setScrollContainerNode] = useState();

  const { state: authState } = React.useContext(AuthContext);

  return (
    <>
      <Header
        color="primary"
        sx={{ flex: '0 0 auto', zIndex: 1 }}
        scrollTarget={scrollContainerNode}
      />
      <Box
        ref={(node) => {
          if (node) {
            setScrollContainerNode(node);
          }
        }}
        sx={{
          flex: '1 1 auto',
          overflow: 'auto',
        }}
      >
        {(authState.isAuthenticated || noLoginRequired) ? (
          <Container disableGutters maxWidth={false}>
            {content}
          </Container>
        ) : (
          <GuestSignIn></GuestSignIn>
        )}
      </Box>
    </>
  );
};

export default HeaderPage;
