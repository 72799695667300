import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import { Link, Typography } from '@mui/material';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import logo from '../assets/img/rho_und_rho_bg.png';
import UserMenu from '../components/UserMenu';

const LogoImg = styled('img', {
  name: 'LogoImg',
})`
  height: 2em;
`;

const Header = (props) => {
  const { scrollTarget, ...rest } = props;
  const pages = ['Home', 'Hochzeit', 'Reisen'];
  const navigate = useNavigate();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: scrollTarget,
  });

  return (
    <AppBar position="static" {...rest} elevation={trigger ? 4 : 0}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Link href="/home">
            <LogoImg sx={{ ml: 1, mr: 10 }} src={logo} alt="Logo" />
          </Link>

          <Box sx={{ display: { xs: 'none', sm: 'flex' }, mr: 5, ml: 'auto' }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  navigate(`/${page.toLowerCase()}`);
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Typography sx={{fontSize: 15}}>
                {page}
                </Typography>
              </Button>
            ))}
          </Box>

          <Box sx={{ display: {xs: 'flex', sm: 'none'}, mr: 2, ml: 'auto' }}>
            <UserMenu pages={pages} navigateTo={(pg) => {navigate(`/${pg.toLowerCase()}`)}}/>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
