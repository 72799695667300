import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1b3624',
    },
    secondary: {
      main: '#822e38',
    },
    warning: {
      main: '#ff9800',
    },
    error: {
      main: '#bf1f43',
    },
    info: {
      main: '#21376b',
    },
  },
  typography: {
    fontFamily: 'Quattrocento',
  },
});

export default theme;
